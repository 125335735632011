import React, { ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Header from "./common/Header";

const GlobalStyle = createGlobalStyle`
  html {
    font-family: Arial, Helvetica, sans-serif;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(166, 179, 187, 0.1);
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 1em;
  margin-top: 60px;
`;

type Props = {
  children: ReactNode;
};
export default function Skeleton({ children }: Props) {
  return (
    <Container>
      <GlobalStyle />
      <Header></Header>
      <Content>{children}</Content>
    </Container>
  );
}
