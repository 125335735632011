import styled from "styled-components";
import { ReactComponent as Logo } from "../logo/logo.svg";

const NavBar = styled.div`
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: white;
  display: flex;
  padding: 0 1em;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

  svg {
    height: 50px;
    width: 50px;
  }

  span {
    padding-left: 0.5em;
    font-size: 2em;
    color: #00009c;
    font-weight: bold;
    font-style: italic;
  }
`;

export default function Header() {
  return (
    <>
      <NavBar>
        <Logo></Logo>
        <span>- KFZ Technik</span>
      </NavBar>
    </>
  );
}
