import Startpage from "./Pages/Startpage";
import Skeleton from "./Skeleton";

function App() {
  return (
    <Skeleton>
      <Startpage />
    </Skeleton>
  );
}

export default App;
