import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faPhone,
  faUserClock
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoText = styled.span`
  padding-left: 12px;
`;

const TextBlock = styled.div`
  padding: 10px 0 10px 0;
`;

const StyledIframe = styled.iframe`
  border: 0;
`;

const AdressBlock = () => {
  return (
    <>
      <TextBlock>
        <FontAwesomeIcon icon={faMapPin} />
        <InfoText>Schulstraße 14, 22941 Delingsdorf</InfoText>
      </TextBlock>
      <TextBlock>
        <FontAwesomeIcon icon={faUserClock} />
        <InfoText>Mo-Do: 09:00 - 18:00, Fr: 09:00 - 17:00</InfoText>
      </TextBlock>
      <TextBlock>
        <FontAwesomeIcon icon={faPhone} />
        <InfoText>04532 4088286</InfoText>
      </TextBlock>
    </>
  );
};

const Map = () => {
  return (
    <StyledIframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7309.4354098291515!2d10.25197941275387!3d53.70770331531324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa0c7abe1f0dd589f!2sSi-Kfz%20Technik%20UG%20(haftungsbeschr%C3%A4nkt)!5e0!3m2!1sde!2sde!4v1609941685306!5m2!1sde!2sde"
      width="600"
      height="450"
      aria-hidden="false"
    ></StyledIframe>
  );
};

const Info = () => {
  return <div>Unser Internetauftritt befindet sich grade im Aufbau</div>;
};

const Startpage = () => {
  return (
    <Wrapper>
      <h2>Ihre KFZ Werkstatt in Delingsdorf</h2>
      <AdressBlock></AdressBlock>
      <Map></Map>
    </Wrapper>
  );
};

export default Startpage;
